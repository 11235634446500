<template>
    <div id="Timer">
        You will be auto logged out in <span id="SecondsUntilExpire">{{IDLE_TIMEOUT - idleSecondsCounter}}</span> seconds.
    </div> 
</template>
<script>
// import { useStore } from 'vuex'
export default {
    data(){
    return{
      // 30min IdleSecTimer 1800sec 
      IDLE_TIMEOUT:10800,
      idleSecondsTimer:0,
      idleSecondsCounter :0
    }
  },
  mounted(){
    document.onclick = ()=> {
      this.idleSecondsCounter = 0;
    };
    // document.onmousemove = ()=> {
    //   this.idleSecondsCounter = 0;
    // };
    document.onkeypress = ()=> {
      this.idleSecondsCounter = 0;
    };
    this.idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
  },
  methods:{
    CheckIdleTime() {
      this.idleSecondsCounter++;
      if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
        window.clearInterval(this.idleSecondsTimer);
        alert("User has been logout, due to a Time expired! CLICK OK.");
        window.location = "https://ipumon.org/my/";
      }
    }
  }
}
</script>