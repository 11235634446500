import { createStore } from 'vuex'
import router  from '../router'
import { doc, getDoc} from 'firebase/firestore'
import { collection, getDocs, query, where, limit, updateDoc } from 'firebase/firestore'
import db from '../firebase/firebaseInit.js'
import { ref} from "@firebase/storage";
import storage from "@/firebase/firebaseInit.js";
import  { auth }  from '../firebase/firebaseInit'
// import { getAuth, fetchSignInMethodsForEmail, EmailAuthProvider} from "firebase/auth";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
 


const store = createStore({
  state: {
    // user
    user: null,
    authIsReady: false,
    profileLoaded: null,
    
    // Messages 
    messagesData: [],
    messagesLoaded: null,

    // site management
    getsite: null,
    page: [],
    photo: null,

    // social media links
    LinksData: [],
    linksloaded: null,

    // Media
    MediaData: [],
    medialoaded: null,

    //
    WidgetsData: [],
    widgetloaded: null,
    wid_num: 0,

  },
 

  mutations: {
    // upload
    createFileURL(state, payload) {
      state.blogPhotoFileURL = payload;
    },
    openPhotoPreview(state) {
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },
    fileNameChange(state, payload) {
      state.MediaItemName = payload;
    },
    // user
    setUser(state, payload) {
      state.user = payload
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    },  
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      // state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().FirstName;
      state.profileLastName = doc.data().LastName;
      state.profileUsername = doc.data().Username;
      state.membership = doc.data().collectives;
      state.profileEmail = doc.data().user_Email;
      state.profileNumber = doc.data().user_Phone_Number;
      state.profileBirthday = doc.data().user_BirthDay;
      state.profileZip = doc.data().user_Zip;
      state.getsite = doc.data().site
      
    },
    setsitemode(state, payload) {
      state.getsite = payload;
    },
    changeZip(state, payload) {
      state.profileZip = payload;
    },
    changeNumber(state, payload) {
      state.profileNumber = payload;
    },
    changeEmail(state, payload) {
      state.profileEmail = payload;
    },
    // messages
    SetMessages(state, payload) {
      state.messagesData.push(payload);
    },
    messages_loaded(state) {
      state.messagesLoaded = true
    },
    // links
    SetMediaicon(state, payload) {
      state.LinksData.push(payload);
      },
    linksloaded(state) {
      state.linksloaded = true
    },
    clearlinks(state) {
      state.LinksData = []
    },
    // media 
    SetMedia(state, payload) {
      state.MediaData.push(payload);
      },
    medget_loaded(state) {
    state.medialoaded = true
    },
    // other 
    profileLoaded(state) {
      state.profileLoaded = true;
    },
    setContent(state, doc) {
      // live
      state.siteId = doc.id;
      state.Widget = doc.data().Widget;
      state.Widget_Editor = doc.data().Widget;
      state.title = doc.data().title;
      state.page.column  = doc.data().column;
      state.page.mediat = doc.data().mediat;
      state.page.social = doc.data().social;
      state.page.widgett = doc.data().widgett;
      
      // other ?????
      // state.page_tem = doc.data().Page;
      // state.soclinks = doc.data().Social;
      // state.tem = doc.data().Template;
      // state.siteId = doc.id;
      // state.Widget = doc.data().Widget
      // not LIVE Items.
      //////////////////////////////
      // state.site = doc.data().title;
      // state.t_con1 = doc.data().con1_t;
      // state.c_con1 = doc.data().con1_c;
      // state.t_con2 = doc.data().con2_t;
      // state.c_con2 = doc.data().con2_c;
      // state.t_con3 = doc.data().con3_t;
      // state.c_con3 = doc.data().con3_c;
      // state.t_con4 = doc.data().con4_t;
      // state.c_con4 = doc.data().con4_c;
      // state.med_t = doc.data().media_t;
      // state.abo = doc.data().about;
      // state.abo_i = doc.data().about_image;
      // state.m1 = doc.data().m1;
      // state.m2 = doc.data().m2;
      // state.m3 = doc.data().m3;
      // state.m4 = doc.data().m4;
      // state.m5 = doc.data().m5;
      // state.m6 = doc.data().m6;
      // state.m7 = doc.data().m7;
      // state.m8 = doc.data().m8;
      // state.l_sm1 = doc.data().sm1_l;
      // state.l_sm2 = doc.data().sm2_l;
      // state.l_sm3 = doc.data().sm3_l;
      // state.email = doc.data().email;
      // state.msz = doc.data().msz;
    },
    changetitle(state, payload) {
      state.site = payload;
    },
    changeaboutme(state, payload) {
      state.abo = payload;
    },
    // change_con_one(state, payload) {
    //   state.c_con1 = payload;
    // },
    // change_con_two(state, payload) {
    //   state.c_con2 = payload;
    // },
    // change_con_three(state, payload) {
    //   state.c_con3 = payload;
    // },
    // change_con_four(state, payload) {
    //   state.c_con4 = payload;
    // },
    //SLinks
    setlinkicon(state, payload) {
      state.stype = payload;
    },
    sociallink(state, payload) {
      state.slinks = payload;
    },
    // end of widgets
    // widgets
    SetWidget(state, payload) {
      state.WidgetsData.push(payload);
    },
    widgetloaded(state) {
      state.widgetloaded = true
    },
    clearwidget(state) {
      state.WidgetsData = []
    },
    // edit mode
    settitle(state, payload) {
      state.wid_title = payload;
    },
    settext(state, payload) {
      state.wid_text = payload;
    },
    setwnum(state, payload) {
      state.wid_num = payload;
    },
    setwidget(state, payload) {
      state.widget = payload
    },
    // end of widgets
    // Site 
    column(state, payload) {
      state.page.column = payload;
    },
    mediatype(state, payload) {
      state.page.mediat = payload;
    },
    socialtype(state, payload) {
      state.page.social = payload
    },
    widgettype(state, payload) {
      state.page.widgett = payload
    },
  },
  

  actions: {
  


    //email link
  //   async emaillink() {
  //     const actionCodeSettings = {
  //       url:"http://127.0.0.1:8080/",
  //       handleCodeInApp: true,
  //     },
  //     sendSignInLinkToEmail(auth, email, actionCodeSettings)
  //     .then(() => {
  //      window.localStorage.setItem('emailForSignIn', email)
  // })
  //   },
    
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },
    // async logout(context) {
    //   console.log('logout action')

    //   await signOut(auth)
    //   context.commit('setUser', null)
    // },
    async logout(context) {
      await signOut(auth)
      context.commit('setUser', null)
      window.location = "/"
    },

    fetchUser ({ commit }) {
      var currentUrl = window.location.pathname;
      auth.onAuthStateChanged(async user => {
        if (user === null){
          return
        } else {
          commit('setUser', user)
          const useruid = user.uid
          const docRef = doc(db, "user", useruid);
          const docSnap = await getDoc(docRef);
          commit("setProfileInfo", docSnap);
          // commit("setProfileInitials");
          commit("profileLoaded")
          
          
          if(router.isReady() && router.currentRoute.value.path === '/auth/login') {
            router.push(currentUrl)
          }
        }
      })
    },
    
    async updateUserSettings({ state, commit }) {
      auth.onAuthStateChanged(async user => { 
        commit('setUser', user)
        const useruid = user.uid
        const dataBase = await doc(db, "user", useruid);
        await updateDoc(dataBase,{
          user_Zip: state.profileZip,
          user_Phone_Number: state.profileNumber,
          user_Email: state.profileEmail,
        });
      })
    },
    // end user
    // messages
    async Get_Messages({commit, state}) {
      const getData = query(collection(db, 'messages'), where("live", "==", true));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.messagesData.some(messages => messages.docId === doc.id)) {
          const data = {
            docId: doc.id,
            messagesTitle: doc.data().Title,
            messagesText: doc.data().text,
            messagesLink: doc.data().link,
          };
          commit("SetMessages", data);
        }
      });
      commit('messages_loaded')
    },
    // end message
    // links 
    async linksreset ({commit}) {
      commit('clearlinks')
    },
    async SocialLinkGet ({ commit, state }) {
      // const url_host = window.location.hostname
      const sitemode = state.getsite;
      console.log('links');
      const getData = query(collection(db,"Links"), where("site", "==", sitemode), limit(5));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.LinksData.some((links) => links.docId === doc.id)) {
          const data = {
            docId: doc.id,
            slinks: doc.data().slinks,
            sicon: doc.data().stype,
          };
        commit("SetMediaicon", data);
      }
      });
      commit('linksloaded')
    },
    //
    async resetwidget ({commit}) {
      commit('clearwidget')
    },
    async WidgetGet ({ commit, state }) {
      // const url_host = window.location.hostname
      const sitemode = state.getsite;
      console.log("widget"+ sitemode)
      const getData = query(collection(db,"Widgets"), where("site", "==", sitemode), limit(5));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.WidgetsData.some((widget) => widget.docId === doc.id)) {
          const data = {
            docId: doc.id,
            site: doc.data().site,
            widget: doc.data().widget,
            wid_num: doc.data().wid_num,
            wid_title: doc.data().wid_title,
            wid_text: doc.data().wid_text,
            wid_media: doc.data().wid_media,
            wid_media_mode: doc.data().wid_media_mode,
          };
        commit("SetWidget", data);
      }
      });
      commit('widgetloaded')
    },


    //
    // content
    async fetchcontent ({state, commit}) {
      const sitemode = state.getsite;
      console.log(sitemode)
      const docRef = doc(db, "Sites", sitemode);
      const docSnap = await getDoc(docRef);   
      commit("setContent", docSnap);
    },
    async updateContent({ state }) {
      const sitemode = state.getsite;
      const dataBase = await doc(db, "Sites", sitemode);
      await updateDoc(dataBase,{
        title: state.site,
      });
    },
    // media
    async MediaGet ({ commit, state }) {
      const url_host = state.getsite;
      const getData = query(collection(db,"Media"), where("site", "==", url_host), limit(8));
      const results = await getDocs(getData);
      results.forEach(doc => {
        if (!state.MediaData.some((media) => media.docId === doc.id)) {
          const data = {
            docId: doc.id,
            img: doc.data().img,
            side: doc.data().side,
          };
        commit("SetMedia", data);
      }
      });
      commit('medget_loaded')
    },
    // images
    imageHandler() {
      // const MediaRef = ref(storage, 'Images/mountains.jpg');
      const storageRef = ref(storage, ("Images.jpg"));
      console.log(storageRef, "ok")
    }
  },


  modules: {}
})

const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  unsub()
})

// export the store
export default store