import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAU0scJmbjwTIYcwQbUicJNwWykz7DjYbc",
    authDomain: "web-collective-ipumon.firebaseapp.com",
    databaseURL: "https://web-collective-ipumon-default-rtdb.firebaseio.com",
    projectId: "web-collective-ipumon",
    storageBucket: "web-collective-ipumon.appspot.com",
    messagingSenderId: "588667039931",
    appId: "1:588667039931:web:7a908e2453c0373b74799e",
    measurementId: "G-BKMGPNS34V"
};
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage }
export default db