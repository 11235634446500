<template>
  <header id="nav" >
    <nav class="nav-a">
      <div class="left" >
        <h1>Web-Collective <a target="_blank" href="https://www.ipumon.com/#UA"><b id="backUA">IPUMON </b></a></h1>
        <h4>Beta</h4>
      </div>
    <template v-if="user">
      <div class="right">
        <span>Logged in as {{ user.email }}</span>
        <button @click="handleClick">Logout</button>
      </div>
    </template>
  </nav>
  <nav>
    <template v-if="user">
       <!-- for all users -->
      <div class="nav-c">
        <router-link to="/">Dashboard</router-link>
        <a target="_blank" href="https://ipumon.org/payment/dues">Dues</a>
        <a target="_blank" href="https://ipumon.org/profile">Profile</a>
        <li class="dropdown">
          <a href="javascript:void(0)" class="dropbtn">Site Manager</a>
          <div class="dropdown-content">
            <router-link to="/site/editor">Site </router-link>
            <router-link to="/site/content">Site Content</router-link>
            <!-- <router-link to="/site/cv-resume/editor/">Site CV resume</router-link> -->
            <!-- <router-link to="/site/media">Site Media</router-link> -->
          </div>
        </li>
      </div>
    </template>
  </nav>
  </header>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  setup() {
    const store = useStore()
    const handleClick = () => {
      store.dispatch('logout')
    }
    return {
      handleClick,
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady)
    }
  } 
}
</script>
<style>
/* other icon and background*/
#backUA {
  background: linear-gradient(
    to bottom,
    #0057b7 50%, #ffd700 50%
  );
}


#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
/* font imports */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* base styles */
body {
  font-family: "Poppins";
}
.container {
  padding: 5%;
  /* margin: 0 auto; */
}

/* navbar */
header{
  padding: 10%; 

}
.nav-a{
  display: flex;
}
.nav-a .left{
  margin-right: auto;
}
.nav-a .left h4, .nav-a .left b{
  letter-spacing: 5px;
}
.nav-a .left h4{
  float: left;
  margin: 0%;
}
.nav-a .right{
  margin-left: auto;
}
.nav-b{
  float: left;
  
}
.nav-c {
  float: right;
  display: inline-block;
  font-size: 20px;
  margin-right: 10%
  
}

.nav-c a, .dropbtn {
  display: inline-block;
  color: black;
  text-align: center;
  text-decoration: none;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
  display: block;
}





nav {
  align-items: center;
}
nav a, nav button, nav span {
  margin-left: 20px;
}
nav a {
  color: #444;
  text-decoration: none;
  font-weight: bold,
}
nav span {
  color: #777;
}
</style>