import { createRouter, createWebHistory } from 'vue-router'
// import Login from '../views/Auth/LogIn.vue'
import { auth } from '../firebase/firebaseInit'
// import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
    
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/public/AbouT.vue'),
    // component: () => import('../views/public/AbouT.vue'),
  },
  {
    path: '/link',
    name: 'link',
    component: () => import('../views/Other/LinK.vue'),
    // component: () => import('../views/public/AbouT.vue'),
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/DashBoard.vue'),
    meta: {
      title: 'Welcome',
      requiresAuth: true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/Other/TesT.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/:user/:key',
    name: 'AuthPass',
    component: () => import('../views/Auth/AuthCheck.vue'),
  },
  {
    path: '/auth/',
    name: 'authipumon',
    component: () => import('../views/Auth/AuthPass.vue'),
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/Auth/LogIn.vue'),
  },
  {
    path: '/auth/logout',
    name: 'LogOut',
    component: () => import('../views/Auth/LogOut.vue'),
  },
  {
    path: '/auth/reset',
    name: 'ReSet',
    component: () => import('../views/Auth/ResetPassword.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/ManGer.vue'),
  },
  // {
  //   path: '/profile',
  //   name: 'ProFile',
  //   component: () => import('../views/ProFile.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/help',
    name: 'HelpContect',
    component: () => import('../views/Other/HelpContect.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/site/',
  //   name: 'Site Layout Editor',
  //   component: () => import('../views/Site/LayOut.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  // {
  //   path: '/dues',
  //   name: 'Member-Dues',
  //   component: () => import('../views/Other/DuEs.vue'),
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  {
    path: '/site/editor',
    name: 'Site Layout',
    component: () => import('../views/Editor/SLEditor.vue'),
    meta: {
      title: 'Layout',
      requiresAuth: true
    }
  },
  {
    path: '/site/content',
    name: 'SiteContent',
    component: () => import('../views/Editor/SiteContent.vue'),
    meta: {
      requiresAuth: true,
      title: 'Content',
    }
  },
  {
    path: '/site/cv-resume/editor/',
    name: 'Editor CV-Resume',
    component: () => import('../views/Editor/CV-ResEditor.vue'),
    meta: {
      requiresAuth: true,
      title: 'CV-Resume Editor',
    }
  },
  {
    path: '/site/cv-resume/',
    name: 'CV-Resume',
    component: () => import('../views/Site/CV-Resume.vue'),
    meta: {
      requiresAuth: true,
      title: 'CV-Resume',
    }
  },
  {
    path: '/site/media',
    name: 'SiteMedia',
    component: () => import('../views/Editor/SiteMedia.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, form, next) => {
  // const browserHeaderTitle = to.name
  document.title = to.meta.title ? to.meta.title : 'Web-C IPUMON';
  var currentUrl = window.location.pathname;
  if (to.path === currentUrl && auth.currentUser) {
    next()
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next("/auth/login")
    return;
  }

  next();
})

export default router