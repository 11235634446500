<template>
    <footer>
        <div class="item1">
            <p>Federation of IPUMON 2022 &copy;</p>
        </div>
        <div class="item1-a">
          <p><b>Site Version: Beta </b><a target="_blank" href="https://docs.google.com/document/d/1pbccqIMpUlH6ZTEC9GmSKlnbavCUUWV73urNEs_0ejA/edit#heading=h.k30i2i50gi8q">0.03.05</a></p>
        </div>
        <div class="item2">
          <p>IPUMON service</p>
          <ul>
            <li><a target="_blank" href="https://ipumon.org/about">Misson</a></li>
            <li><a target="_blank" href="https://ipumon.org/dues">Member Dues-Credit</a></li>
            <li><a target="_blank" href="https://docs.google.com/document/d/1jfES4keBd-OeKabrr0X58oZfNB9fXBqYKwQS-qTK-FU/edit?usp=sharing">Funds Report 2023</a></li>
          </ul>
        </div>
        <div class="item3">
          <p>User</p>
          <ul>
            <li><a href="mailto:help@ipumon.com?subject=Issue with web-s.ipumon.net&body=What page did the error or breaked occurred:">Report Issues</a></li>
            <template v-if="$store.state.user"><li><a href="/help">Help with site</a></li></template>
            <!-- <template v-if="$store.state.user"><li><a target="_blank" href="/test">View your site</a></li></template> -->
            <template v-if="!$store.state.user"><li ><a href="/auth/login">Login</a></li></template>
            <template v-if="$store.state.user"><li><a href="https://docs.google.com/forms/d/e/1FAIpQLSf16kdgeKKQmJOEFRLcGnaXml4cbl2xi52sGfR2Lut_nsnQsg/viewform">Suggest</a></li></template>
          </ul>
        </div>
        <div class="item4">
          <p>IPUMON Terms</p>
          <ul>
            <li><a target="_blank" href="https://ipumon.org/terms">Terms-Of-Use</a></li>
            <li><a target="_blank" href="https://ipumon.org/terms/Web-Privacy-Policy">Web-Privacy-Policy</a></li>
            <!-- <li><a target="_blank" href="/Data-Cookie-Policy">Data-Cookie-Policy</a></li> -->
          </ul>
        </div>        
    </footer>
</template>

<style>
.item1 {
  grid-area: note;
}
.item1-a {
  grid-area: ver;
}
.item2 {
  grid-area: link1;
}
.item3 {
  grid-area: link2;
}
.item3 {
  grid-area: link3;
}
.item1 p, .item1-a p{
  float: right;
  color: black;
  font-size: 20px;
}
footer {
  display: grid;
  background-color: #999;
  grid-template-areas: 
  'link1 link2 link3 note'
  'link5 link6 link7 ver';
  padding: 5%;
  width: 100%;
  
}
ul {
  list-style-type: none;
  margin-right: 0;
  overflow: hidden;
  font-size: 20px;
}
li a {
  text-decoration: none;
  color: black;
  font-size: medium
}
</style>