<template>
  <Navbar id="Head"/>
  <div style="margin-bottom:210px;" class="container">
    <router-view/>
  </div>
  <Footer id="Footer" />
  <div id="Loader" v-if="$store.state.user">
    <Timer />
  </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore, mapActions } from 'vuex'
import Navbar from './components/main/NavBar'
import Footer from './components/main/FootEr.vue'
import Timer from './components/main/TimeR.vue'

export default {
  components: {Navbar, Footer, Timer},
  setup() {
    const store = useStore()
    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  },
  methods: {
    ...mapActions([ "modalActive", "fetchcontent"])
  }
}

</script>
<style>

</style>
<style>
* {
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #111;
}
button {
  background:#00dba4;
  border: 0;
  padding: 6px 10px;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}
input[type=text], input[type=number], textarea, select {
  width: 100%;
  padding: 2% 2%;
  margin: .5% 0;
  box-sizing: border-box;
}
/* blogs */
.blog {
  padding: 10px;
  margin: 30px 0 20px;
  border: 1px dotted #ddd;
}
.blog p {
  color: #999;
}
.blog h3 {
  color: #00dba4;
}
.blog .icons {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}
.blog .icons .material-icons {
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}
.blog .icons .material-icons:hover {
  color: #00dba4;
}
.forma input {
	appearance: none;
	border: none;
	outline: none;
	background: none;
	display: block;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	font-size: 1.5rem;
	margin-bottom: 2rem;
	padding: 0.5rem 0rem;
}
</style>